.Home {
  height: calc(100vh - 75px);
}

.Home .content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Home h1 div {
  line-height: 50px;
}

.Home h1 mark {
  color: #FFADAD;
  background: transparent;
}

.Home h1 p {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
}

.Home .logo {
  margin-left: 50px;
  box-shadow: 0 0 5px 7px #3C3C3C;
  border-radius: 50px;
  position: relative;
  z-index: 1;
}

.Home .logo img {
  width: 100%;
  height: 100%;
}
