* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-color: #1e1e1e;
  font-family: Roboto, sans-serif;
}

html {
  height: 100%;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  
}

#root {
  height: 100%;
  overflow: hidden;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 75px;
  background: #1B1B1B;
  font-size: 1.2rem;
  z-index: 1;
}

footer a {
  position: absolute;
  right: 30px;
  font-size: .1em;
  opacity: 65%;
}

footer p {
  opacity: 65%;
}

footer a:hover {
  opacity: 100%;
}